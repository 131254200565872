import { useEffect, useState } from 'react';
import { useCalendlyEventListener, PopupModal } from 'react-calendly';
import PropTypes from 'prop-types';

import appSettings from '../../app-settings';
import { useAccount } from '../../store/account/hooks';
import Button from '../buttons/Button';
import AlertMessage from '../alerts/AlertMessage';
import { ONBOARDING_STEPS } from '../../core/constants';
import Loader from '../loader/Loader';
import SubscriptionLayout from './SubscriptionLayout';

const ScheduleCallStep = ({ setStep }) => {
  const [open, setOpen] = useState(false);
  const { account, loading, error, setError, setOnboardingCall } = useAccount();

  useEffect(() => {
    // Removes query string params from previous step (if any)
    window.history.replaceState({}, document.title, '/');
  }, []);

  useCalendlyEventListener({
    onEventScheduled: () => setOnboardingCall(),
  });

  const handleModalClose = () => {
    setOpen(false);
    if (account?.onboardingCall) {
      setStep(ONBOARDING_STEPS.startOnboarding);
    }
  };

  return (
    <SubscriptionLayout>
      <div className="flex size-full flex-col items-center justify-center">
        <div className="max-w-[436px] space-y-8">
          <div className="space-y-4">
            <h1 className="text-h2 font-semibold">Schedule Your Onboarding Call</h1>
            <p className="text-lg">
              Welcome to DonorSpring! We&apos;re excited to have you in this community. As a first step, please schedule
              your 1:1 onboarding call with us; this is where we&apos;ll walk you through the DonorSpring platform and
              share essential tips and best practices.
            </p>
            <p className="text-lg">
              Your next step is to set up your account. Our step-by-step instructions will guide you, but please
              don&apos;t hesitate to reach out if you need any assistance. Once you are set up, you are free to explore
              the platform! We encourage you to familiarize yourself ahead of the onboarding call, so we can address any
              of your questions then and there.
            </p>
          </div>
          <div className="space-y-4">
            <Button
              title="Schedule Onboarding Call"
              color="primary"
              size="xl"
              className="w-full"
              onClick={() => setOpen(true)}
              disabled={loading}
            />
            <Button
              title="I Already Scheduled My Call"
              color="destructive"
              variant="outlined"
              size="lg"
              className="w-full"
              onClick={async () => {
                await setOnboardingCall();
                setStep(ONBOARDING_STEPS.startOnboarding);
              }}
              disabled={loading}
            />
          </div>
        </div>

        <PopupModal
          url={appSettings.calendlyUrl}
          onModalClose={handleModalClose}
          open={open}
          rootElement={document.getElementById('root')}
          prefill={{ name: account?.organizationName }}
        />

        {/* calendly popup modal z-index is 9999 */}
        {loading && <Loader className="z-[99999]" />}
      </div>

      <AlertMessage
        open={!!error}
        message={typeof error === 'string' ? error : 'Oops, something went wrong!'}
        onClose={() => setError(false)}
        severity="error"
      />
    </SubscriptionLayout>
  );
};

ScheduleCallStep.propTypes = {
  setStep: PropTypes.func.isRequired,
};

export default ScheduleCallStep;
